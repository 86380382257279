@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Bahnschrift";
  src: local("Bahnschrift"),
    url(./theme/global/fonts/Bahnschrift.woff) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

/* pt-sans-narrow-regular - latin-ext_latin */
@font-face {
  font-family: "PT Sans Narrow";
  src: local("PT Sans Narrow"),
    url(./theme/global/fonts/pt-sans-narrow.woff) format("woff");
}

/* great vibes - latin-ext_latin */
@font-face {
  font-family: "Great Vibes";
  src: local("Great Vibes"),
    url(./theme/global/fonts/GreatVibes-Regular.woff) format("woff");
}
