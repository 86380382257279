


.modalContainer2 {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 100;
    opacity: 0;
    transition: all .5s ease-in-out;
    pointer-events: none;
}
.modalContainer2 .modal {
    width: 100vw;
    height: auto;
    background-color: #fff;
    padding: 2rem 3rem 2rem 3rem;
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
    box-shadow: 0 0px 3rem 0 rgba(0, 0, 0, 0.3);
    transform: translateY(300px);
    transition: all .5s ease-in-out;
}

.modalContainer2 .modal_header {
    display: flex;
    gap: 0rem;
    margin: 0rem 0;
}
.modalContainer2 .modal_header-title {
    margin: 0;
    padding: 0;
    font-size: 32px;
}

.modalContainer2 .modal_content p {
    text-align: left;
    padding: 0;
    margin-top: 40px;
}
.modalContainer2 .modal_footer {
    padding: 2rem 0;
    padding-bottom: 0;
    display: flex;
    justify-content: right;


    
}
.modalContainer2 .modal_footer button {
    width: 100%;
}
.modalContainer2 .modal_footer .modal-close {

}

.modalContainer2 .modal_footer .secondaryButton {
    background-color: transparent;
    color: var(--text-color);
}


.modalContainer2.show .modal {
    transform: translateY(0);

}

.modalContainer2.modal_footer  {
    pointer-events: initial;
    opacity: 1;
}


.modalContainer2.show {
    opacity: 1;
    pointer-events: initial;
}