@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500&display=swap");
@import url("../css/animate.min.css");

a {
  font-family: "Bahnschrift";
  font-size: 14px;
  color: #666566;
}

.device-disclaimer {
  display: grid;
  height: 100vh;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-items: center;
  font-size: 24px;
  background-image: url("../images/roses-bg-schedule.png");
  background-position: top left;
  background-size: cover;
  background-repeat: repeat-y;
}

.app-update {
  background-image: url("../images/main_bg3.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  text-align: center;
}

.app-update button {
  background: var(--accent-color);
  padding: 10px 20px;
  color: #fff;
  border: 0;
  width: 80%;
  margin: 20px 0;
}

.landing {
  display: grid;
  height: 100vh;
  grid-template-columns: 8;
  grid-template-rows: 7;
  justify-content: center;
  justify-items: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.landing .item1 {
  grid-column: 1 / 9;
  grid-row: 1 / 2;
  align-self: flex-end;
}

.landing .item1 h1 {
  line-height: 60px;
}

.item1 .custom-title {
  padding-bottom: 0px;
  margin-top: 20px;
  overflow-y: hidden;
}

.landing .item1 div {
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-family: "Great Vibes";
  color: var(--text-color);
}

.landing .item4 {
  grid-column: 1 / 9;
  grid-row: 3 / 4;
  align-self: flex-end;
  font-size: 0.9rem;
  color: var(--text-color);
  padding: 0 60px;
  line-height: 1.5rem;
  text-align: center;
}

.landing .item5 {
  grid-column: 1 / 9;
  grid-row: 4 / 6;
  align-self: center;
  width: 100%;
  text-align: center;
}

.landing .item6 {
  grid-column: 1 / 9;
  grid-row: 6 / 7;
  align-self: center;
  width: 100%;
  text-align: center;
}

.landing .item6 p {
  font-size: 10px;
}

.landing .item7 {
  grid-column: 1 / 9;
  grid-row: 7 / 8;
  height: 50px;
  align-self: flex-start;
  background: url("../../global/icons/separator.svg");
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center center;
  width: 100%;
}

.date-container {
  display: grid;
  grid-template-columns: 1fr 90px 1fr;
  grid-template-rows: 100px;
  justify-content: initial;
}

.dueyear {
  border-left: 1px solid var(--text-color);
  border-right: 1px solid var(--text-color);
  color: var(--accent-color);
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  align-self: center;

  padding: 0 20px;
}
.dueyear span {
  display: block;
  font-size: 0.8rem;
  color: #7f7f7f;
}

.dueday,
.duemonth {
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  align-self: center;
  color: var(--text-secondary-color);
}

.Toastify__toast--success {
  z-index: 999999 !important;
  background: #6bc46e !important;
  padding: 30px !important;
  border-radius: 10px !important;
  margin: 10px !important;
  /* font-family: 'Montserrat'; */
  font-size: 14px !important;
  font-weight: 100 !important;
  position: relative !important;
  top: 30% !important;
}

.Toastify__toast--error {
  z-index: 999999 !important;
  background: #d07067 !important;
  padding: 30px !important;
  border-radius: 10px !important;
  margin: 10px !important;
  /* font-family: 'Montserrat'; */
  font-size: 14px !important;
  font-weight: 100 !important;
  position: relative !important;
  top: 30% !important;
}

.Toastify__toast-container--top-left,
.Toastify__toast-container--top-center,
.Toastify__toast-container--top-right {
  top: 0;
  transform: translateX(0);
  height: 100vh !important;
}

:root {
  --accent-color: goldenrod;
  --text-color: #686867;
  --text-secondary-color: #231f20;
  --background-accent-color: #fffbf8;
  --background-accent-secondary: #dbc29a;
  --background-color: #fff;
}

h1,
h2,
h3 {
  font-family: "Great Vibes";
  font-weight: 100;
  text-align: center;
  padding: 20px;
}

h2 {
  margin-bottom: 1rem;
}

.myEvent h1 {
  font-size: 6rem;
  color: var(--accent-color);
}

.myEvent h2 {
  font-size: 2.5rem;
  color: var(--text-color);
}

* {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
/*
#root {
  max-width: 700px;
  margin: 0 auto;
}*/

body {
  background: #fff;
  color: var(--accent-color);

  font-family: "Montserrat";
}

.myEvent-container .myEvent:nth-child(even) {
  background-color: #fff;
}

/* MAIN PAGE START */

.main-separator {
  display: grid;
  grid-row: 8 / 9;
  grid-template-columns: 1fr;
  grid-template-columns: repeat(auto-fill, max(600px, 1fr));
  text-align: center;
  background: url("../../global/icons/separator.svg");
  background-size: 100px;
  background-position: center;
  background-repeat: no-repeat;

  opacity: 0.5;
}
/*
.myEvent.mainpage-container {
  display: grid;
   grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-template-columns: 100%;
  height: 100vh;
}

.mainpage-background {
  grid-column: 1 / 4;
  grid-row: 1 / 5;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100vh;
}*/

.landing {
  background-image: url("../images/main_bg.png");
}

/*
.mainpage-content {
  grid-column: 1 / 4;
}

.mainpage-content.title {
  grid-row: 1 / 3;
  text-align: center;
  margin: auto;
  align-items: center;
  position: relative;
}


.mainpage-content.title h1 {
  padding: 0;
}
*/

/*
.mainpage-content.intro {
  grid-row: 3 / 5;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  text-align: center;
  align-items: center;
  font-size: 0.9rem;
  color: var(--text-color);
  padding: 0 70px;
  margin: 0;
  line-height: 1.5rem;
}*/
/*
.mainpage-content.duedate {
  grid-row: 5 / 6;
  display: grid;
  grid-template-columns: 1fr 90px 1fr;
  grid-template-rows: repeat(3, 1fr);
  align-items: center;
  text-align: center;
  font-size: 1rem;
}

.mainpage-content.duedate .dayname {
  grid-row: 1 / 4;
  grid-column: 1 / 2;
  font-weight: 500;
}

.mainpage-content.duedate .dateyear {
  border-left: 1px solid var(--text-color);
  border-right: 1px solid var(--text-color);
  grid-column: 2 / 3;
  grid-row: 1 / 4;
  color: var(--accent-color);
  font-size: 2.5rem;
  font-weight: 500;
}
.mainpage-content.duedate .dateyear span {
  display: block;
  font-size: 0.8rem;
  color: #7f7f7f;
}

.mainpage-content.duedate .monthname {
  grid-row: 1 / 4;
  grid-column: 3 / 4;
  font-weight: 500;
}

.mainpage-content.duedate .dayname,
.mainpage-content.duedate .monthname {
  text-transform: uppercase;
  color: var(--text-secondary-color);
} */

.mainpage-content.countdown {
  display: grid;
  grid-row: 6 / 8;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  text-align: center;
  font-size: 2rem;
  color: var(--accent-color);
  align-items: center;
  margin-top: 0.5rem;
  overflow: hidden;
}

/* MAIN PAGE END */

.countdown-pulse {
  animation: pulse 1.5s ease infinite;
}

.countdown-element {
  background-repeat: no-repeat;
  background-size: 80px;
  background-position: center 3px;
  animation: pulse 1.5s ease infinite;
  -webkit-animation: pulse 1.5s ease infinite;
  -moz-animation: pulse ease 1.5s;
  -o-animation: pulse ease 1.5s;
  -ms-animation: pulse ease 1.5s;
  height: 100px;
  padding-top: 20px;
  color: var(--accent-color);
}

.webp .countdown-element {
  background-image: url("../images/countdown-circle_sm.webp");
}

.no-webp .countdown-element {
  background-image: url("../images/countdown-circle_sm.png");
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
@-moz-keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
@-o-keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

.countdown-element span {
  display: block;
  font-size: 10px;
  line-height: 10px;
  color: var(--text-color);
  overflow-y: hidden;
}

/* COUNTDOWN END */

/* COUPLES PAGE START */

.couplespage-container {
  position: relative;
  overflow: hidden;
}
.couplespage-container p {
  margin: 1rem 0;
  text-align: center;
  font-size: 14px;
}

.couplespage-container h3 {
  font-size: 1.7rem;
  color: var(--text-secondary-color);
}

.couplespage-container p {
  padding: 20px;
}

.couple p,
.witnesses p,
.presents p {
  font-size: 18px;
  padding: 0;
  text-align: center;
}

.couple,
.witnesses {
  display: flex;
  justify-content: center;
  text-align: center;
  overflow-x: hidden;
  overflow-y: hidden;
}

.couple .image,
.witnesses .image {
  width: 90%;
  max-width: 600px;
  border-radius: 50%;
}

.tester {
  background-image: url("../images/roses-bg-schedule.png");
  background-position: top left;
  background-size: 200%;
  background-repeat: repeat-y;
}

.presents {
  text-align: center;
}

.presents p {
  padding: 0 16px;
  margin: 0;
}

.presents img {
  margin: 0 40px 60px 20px;
  text-align: center;
}

.witnesses {
  margin-bottom: 3rem;
}

.witnesses .image {
  width: 70%;
}

/* COUPLES PAGE END */

/* .schedule-list {
  max-width: 768px;
  margin: 0 auto;
} */

.schedule {
  display: grid;
  grid-template-columns: 40px 40px 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto 1fr;
  gap: 0 0;

  margin-bottom: 20px;

  grid-template-areas:
    "icon icon date date date date date date"
    "icon icon title title title title title title"
    ". description description description description description description description";
}

.schedule a {
  color: var(--text-color);
}

.schedule-list {
  overflow-y: hidden;
}

.schedule > :nth-child(1) {
  margin-top: 0.5rem;
}

.schedule > .title {
  grid-area: title;
  color: var(--text-color);
  font-weight: 500;
  padding-left: 10px;
}

.schedule > .time {
  grid-area: date;
  color: var(--accent-color);
  font-size: 1.2rem;
  font-weight: 500;
  padding-left: 10px;
}

.schedule > .description {
  grid-area: description;
  border-left: 2px solid var(--background-accent-secondary);
  margin-top: 20px;
  padding-left: 50px;
  z-index: 10;
  font-size: 0.8rem;
  font-weight: 400;
  padding-bottom: 1rem;
}

.icon {
  grid-area: icon;
  background-size: 40px 40px;
  background-position: 22px 3px;
  background-repeat: no-repeat;

  grid-row: 1 / 4;
}

.schedule-grid .rings {
  background-image: url("../../global/icons/rings.svg");
}

.schedule-grid .cutlery {
  background-image: url("../../global/icons/cutlery.svg");
}

.schedule-grid .champagne {
  background-image: url("../../global/icons/champagne.svg");
}

.schedule-grid .car {
  background-image: url("../../global/icons/car.svg");
}

.schedule-grid .car2 {
  background-image: url("../../global/icons/car2.svg");
}

.schedule-grid .camera {
  background-image: url("../../global/icons/camera.svg");
}

.schedule-grid .cake {
  background-image: url("../../global/icons/wedding-cake.svg");
}

.schedule-grid .couple {
  background-image: url("../../global/icons/couple.svg");
}

.schedule-grid .amish {
  background-image: url("../../global/icons/amish.svg");
}

.schedule-grid .jam {
  background-image: url("../../global/icons/live-music.svg");
}

.schedule-grid .hearts {
  background-image: url("../../global/icons/hearts.svg");
}

.schedule-grid .dance_shoe {
  background-image: url("../../global/icons/dancing_shoe2.svg");
}

.schedule-grid .dance {
  background-image: url("../../global/icons/dance.svg");
}

.schedule-grid .couple2 {
  background-image: url("../../global/icons/husband_wife.svg");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* .schedule {
  max-width: 1170px;
  display: grid;
  grid-template-columns: 150px repeat(1, 1fr);
  grid-template-rows: repeat(3);
  grid-gap: 10px;
  margin-bottom: 60px;
  position: relative;
}

.schedule-list div {
  width: 150px;
  background-image: url("https://www.pinclipart.com/picdir/big/165-1651180_champagne-glasses-icon-png-clipart.png");
  background-size: 60px;
  background-position: center 0;
  background-repeat: no-repeat;
  border-left: 2px solid red;
}

span {
  width: 150px;
  background-image: url("https://www.pinclipart.com/picdir/big/165-1651180_champagne-glasses-icon-png-clipart.png");
  background-size: 60px;
  background-position: 100px 0;
  background-repeat: no-repeat;
  border-left: 2px solid red;
  position: absolute;
  left: 50px;
  padding-right: 50px;
}

/* @media screen and (min-width: 768px) {
  .schedule-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
 */
/* .schedule * {
  background-color: aqua;
  display: flex;

  font-size: 1.5em;
}

.schedule div {
  grid-row: 1 / 4;
} */

/* MODAL STYLING START HERE */

.ModalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.close-button {
  background: coral;
  padding: 20px;
  border: 1px solid gray;
}

.Toastify__toast--success {
  height: 50vh;
  background: red;
}

.Button {
  min-width: 100px;
  padding: 16px 32px;
  border-radius: 4px;
  border: none;
  background: #141414;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.Background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ModalWrapper {
  width: 90vw;
  height: 80vh;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  z-index: 10;
  border-radius: 50px;
}

.ModalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
}

.ModalContent p {
  margin-bottom: 1rem;
}

.ModalContent button {
  padding: 10px 24px;
  background: #141414;
  color: #fff;
  border: none;
  cursor: pointer;
}

/* MODAL STYLING END HERE */

/* OVERLAY STYLING STARTS HERE*/

.test-overlay {
  position: fixed;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: none;
  text-align: center;
}

.test-overlay img {
  position: relative;
  width: 80px;
  height: 80px;
  top: calc(50% - 40px);
  color: var(--accent-color);
}

.show {
  display: block;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}
/* OVERLAY STYLING ENDS HERE*/

/* NEW THEME STARTS HERE */

* {
  margin: 0;
  padding: 0;
  font-family: "PT Sans Narrow";
}

p {
  font-size: 18px;
  display: block;
  text-align: center;
  padding: 0 20px;
  color: #a8a09c;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
}

h1,
h2 {
  display: block;
  text-align: center;
  margin: 20px;
  color: #40403f;
}

.mainPage-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 150px 1fr 1fr 1fr 1fr 50px;
  height: 100vh;
  background-image: url("../images/main_bg3.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.item {
}
.intro {
  grid-column: 2 / 6;
  grid-row: 1 / 2;
  align-self: end;
  margin-bottom: 20px;
  text-align: center;
  padding: 0 20px;
  font-family: "Bahnschrift";
  text-transform: uppercase;
  color: #9b8579 !important;
}
.names {
  grid-column: 1 / 7;
  grid-row: 2 / 4;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 20px 2fr;
}

.names div {
  text-align: center;
}

.brides_name,
.grooms_name {
  font-size: 84px;
  font-family: "Great Vibes";
  background: -webkit-linear-gradient(
    80deg,
    #ebbe80,
    #c2852c,
    #efdcc2,
    #ecc17d
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow-y: hidden;
}

.brides_name {
  align-self: end;
  align-content: flex-end;
}

.grooms_name {
  align-self: start;
}

.date {
  grid-column: 1 / 7;
  grid-row: 4 / 5;
  display: grid;
  grid-template-columns: 1fr 70px 1fr;
  color: #726658;
}

.countdown {
  grid-column: 1 / 7;
  grid-row: 5 / 6;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
}

.page-separator,
.separator,
.countdown,
.participant_amount {
  overflow: hidden;
}
.separator {
  grid-column: 1 / 7;
  grid-row: 6 / 7;
  text-align: center;

  background: linear-gradient(0deg, white, rgba(255, 255, 255, 0));

  /* background: linear-gradient(rgba(255, 0, 0, 0), rgba(255, 255, 255, 1)); commented out due to issues on iOS */
}

.page-separator {
  height: 50px;
  width: 100%;
  text-align: center;

  background: linear-gradient(0deg, white, rgba(255, 255, 255, 0));

  /*  background: linear-gradient(rgba(255, 0, 0, 0), rgba(255, 255, 255, 1)); commented out due to issues on iOS */
}

.page-separator img,
.separator img {
  display: inline-block;
  height: 50px;
}
/* DATE  STYLING START */

.date .dayname {
  grid-column: 1 / 2;
}
.date .day {
  grid-column: 2 / 3;
  font-size: 50px;
  text-align: center;
  padding-top: 10px;
}
.date .month {
  grid-column: 3 / 4;
}
.date div {
  align-self: center;
}

.date .dayname,
.date .month {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 5px 10px;
  min-width: 100px;
  text-transform: uppercase;
  font-size: 20px;
}

.date .dayname {
  justify-self: right;
}

.date .month {
  justify-self: left;
}

.date .day span {
  display: block;
  font-size: 16px;
  line-height: 16px;
  overflow-y: hidden;
}
/* DATE STYLING END */

/* COUNTDOWN STYLING START */
.countdown .days {
  grid-column: 1 / 2;
}

.countdown .hours {
  grid-column: 2 / 3;
}

.countdown .minutes {
  grid-column: 3 / 4;
}

.countdown .seconds {
  grid-column: 4 / 5;
}

.countdown .days,
.countdown .hours,
.countdown .minutes,
.countdown .seconds {
  background: url("../images/countdown_bg.png") no-repeat;
  background-size: 90%;
  background-position: center top;
  text-align: center;
  padding-top: 30%;
  font-size: 34px;
  line-height: 36px;
  animation: pulse 1.5s ease infinite;
  -webkit-animation: pulse 1.5s ease infinite;
  -moz-animation: pulse ease 1.5s;
  -o-animation: pulse ease 1.5s;
  -ms-animation: pulse ease 1.5s;
  color: #726658;
  text-transform: uppercase;
}

.countdown span {
  display: block;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  overflow: hidden;
}

.countdown-end {
  grid-column: 1 / 5;
  text-align: center;
  padding: 10px;
  align-self: center;
}

.couplesPage-container .heart {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 20px;
}

.couple_photo {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
}

.couple_photo img {
  width: 100%;
}

.couplesPage-container .animated {
  overflow: hidden;
}

.witnesses {
  display: flex;
  justify-content: center;
  text-align: center;
  overflow-x: hidden;
  overflow-y: hidden;
}

.witnesses .image {
  width: 70%;
  max-width: 600px;
  border-radius: 50%;
}

.schedule-container {
  background-image: url("../images/roses-bg-schedule.png");
  background-position: top left;
  background-size: cover;
}

.schedule-grid {
  display: grid;
  padding-right: 20px;

  grid-template-columns: 1fr 10px 10px 60px;
  grid-template-rows: 25px 1fr min(50px);
  grid-template-areas:
    "time line dot icon"
    "title line dot icon"
    ". line dot icon";
  overflow: hidden;
}

.schedule-grid > .time,
.schedule-grid > .title {
  padding: 0 25px 0 10px;
  justify-self: right;
  font-size: 14px;
  position: relative;
  top: 20px;
  color: #666566;
  text-transform: uppercase;
  font-stretch: extra-condensed;
  text-align: right;
  font-family: Bahnschrift;
}

.schedule-grid > .time {
  font-size: 18px;
}

.schedule-grid > .icon {
  grid-area: icon;
  background-size: 40px 40px;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
  top: 15px;
}

.schedule-grid .line {
  grid-area: line;
  border-left: 2px solid #f2ddcc;
}

.schedule-grid > .dot {
  grid-area: dot;
  border: 1px solid #f2ddcc;
  background: #fff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: relative;
  left: -14px;
  top: 30px;
}

.schedule-grid > .dotcolor {
  border: 1px solid #f2ddcc;
}

@keyframes flickerAnimation {
  /* flame pulses */
  0% {
    background-color: #f2ddcc;
  }
  50% {
    background-color: white;
  }
  100% {
    background-color: #f2ddcc;
  }
}

.schedule-grid > .dotcolor {
  opacity: 1;
  background: #f2ddcc;
  border: 1px solid #f2ddcc;
  /* animation: flickerAnimation 2s infinite; */
}

.schedule-grid .rings {
  background-image: url("../../global/icons/rings.svg");
}

.and {
  color: #9b8579;
  overflow-y: hidden;
  position: relative;
  top: -7px;
}

.and span:after,
.and span:before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
  text-decoration: line-through;
  color: #9b8579;
}

/* FORM STYLING STARTS HERE */

.myEvent-form {
  margin-bottom: 30px;
}

.register {
  width: 100%;
}

.register form {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.register label:first-child {
  margin-bottom: 15px;
}

.register label,
.participant_amount label {
  position: relative;
  color: #a8a09c;
  text-transform: uppercase;
  font-size: 14px;
}

.register input[type="text"],
.register textarea {
  width: 100%;
  padding: 10px 0px;
  margin-top: 20px;
  margin-bottom: 10px;
  border: none;
  outline: none;
  font-size: 16px;
  display: block;
  background: transparent;
}
.register input[type="text"] {
  border-bottom: 1px solid #c2852c;
}
.register textarea {
  border-top: 1px solid #c2852c;
  padding: 10px;
  min-height: 80px;
  background-color: #fdf8f2;
  margin-bottom: 40px;
}
.register input[type="text"]::placeholder,
.register textarea::placeholder {
  opacity: 0;
}
.register input[type="text"] + span,
.register textarea + span {
  position: absolute;
  top: 0;
  left: 10px;
  transform: translateY(30px);
  font-size: 14px;
  transition-duration: 300ms;
}

.register label:focus-within > span,
.register input[type="text"]:not(:placeholder-shown) + span,
.register textarea:not(:placeholder-shown) + span {
  color: #a8a09c;
  transform: translateY(0px);
  transform: translateX(-10px);
}

.form_yesno,
.form_participants {
  display: flex;
  color: #fff;
}

.form_yesno div,
.form_participants div {
  flex-grow: 2;
  text-align: center;
  margin: 15px 0 30px;
}

.form_yesno span {
  position: initial;
  padding: 15px 30px;
  background-color: #fdf8f2;
  color: #000;
  display: block;
  background-size: 200% 100%;

  -webkit-transition: background-position 0.5s;
  -moz-transition: background-position 0.5s;
  transition: background-position 0.5s;
}

.form_participants span {
  position: initial;
  padding: 15px 30px;
  background-color: #fdf8f2;
  color: #000;
  display: block;

  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  transition: background-color 0.5s;
}

.form_yesno div span {
  background-image: linear-gradient(
    to right,
    rgba(235, 190, 128, 0.1) 50%,
    #ebbe80 50%
  );
}

.form_yesno div:first-child span,
.form_participants div:first-child span {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-right: 1px solid #fff;
}

.form_yesno div:last-child span,
.form_participants div:last-child span {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-left: 1px solid #fff;
}

.form_yesno input[type="radio"],
.form_participants input[type="radio"] {
  display: none;
}

.form_yesno div input[type="radio"]:checked + span {
  color: #fff;
}

.form_participants div input[type="radio"]:checked + span {
  background-color: #ebbe80;
  color: #fff;
}

.participant_amount.hidden {
  height: 0px;
  -webkit-transition: height 0.5s linear;
  -moz-transition: height 0.5s linear;
  -ms-transition: height 0.5s linear;
  -o-transition: height 0.5s linear;
  transition: height 0.5s linear;
}

.participant_amount.open {
  height: 115px;
  -webkit-transition: height 0.5s linear;
  -moz-transition: height 0.5s linear;
  -ms-transition: height 0.5s linear;
  -o-transition: height 0.5s linear;
  transition: height 0.5s linear;
}

.form_yesno div:first-child input[type="radio"]:checked + span {
  background-position: 100% 0;
}

.form_yesno div:nth-child(2) input[type="radio"]:checked + span {
  background-position: -100% 50%;
}

.form_yesno div:nth-child(3) input[type="radio"]:checked + span {
  background-position: -150% 0;
}

.form_yesno div:last-child input[type="radio"]:checked + span {
  background-position: -100% 0;
}

.form_yesno div input[type="radio"]:checked + span {
  background-color: var(--background-accent-secondary);
}

/* REACT MODAL STARTS HERE */
.ReactModal__Content {
  border: 0 !important;
}

.ReactModal__Overlay h2 {
  color: #000;
  text-align: left;
  font-size: 32px;
  padding: 0;
  margin-bottom: 40px;
}

.ReactModal__Overlay p {
  text-align: left;
  margin-bottom: 40px;
}

.ReactModal__Overlay button {
  padding: 10px 30px;
  background: #ebbe80;
  border: 0;
  border-radius: 18px;
  float: right;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(-100px);
}
/* REACT MODAL ENDS HERE */

/* MODAL OVERLAY STYLING STARTS HERE*/

.modal-overlay {
  position: fixed;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  display: none;
  text-align: center;
}

.modal-overlay div {
  margin: auto;
}

.show {
  display: grid;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}
/* MODAL OVERLAY STYLING ENDS HERE*/

/* Button style */
button {
  border: none;
  border-radius: 25px !important;
  padding: 20px 30px !important;
  line-height: 14px;
  min-width: 150px;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  background-color: #ebbe80;
  background-position: center;
  transition: background 0.8s;
  outline: none;
}

button:hover {
  background: #ebbe80 radial-gradient(circle, transparent 1%, #fadaad 1%)
    center/15000%;
}
button:active {
  background-color: #ebbe80;
  background-size: 100%;
  transition: background 0s;
}

@media only screen and (max-width: 400px) {
  .names .brides_name,
  .names .grooms_name {
    font-size: 66px;
    color: var(--accent-color);
  }

  .countdown div {
    font-size: 24px;
    line-height: 26px;
    background-size: 90%;
  }
}
